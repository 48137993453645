// import $ from 'jquery';
// import { WebpMachine } from 'webp-hero';

import './styles/main.scss';

const $ = window.$;

const removeVimeoModal = e => {
  if (e) {
    e.preventDefault();
  }
  $('div.vimeomodal').remove();
};

window.removeVimeoModal = removeVimeoModal;

const cfShowVimeoModal = id => {
  return e => {
    e.preventDefault();
    $('div.vimeomodal').remove();
    $('body').append($('<div class="vimeomodal"><div class="inner"><div class="framewrap"><iframe src="https://player.vimeo.com/video/' + id + '" allowfullscreen="true"></iframe></div><button onClick="removeVimeoModal()" class="button">Sulje</button></div></div>'));
  };
};

const cfToggleClass = (sel, cn, force = undefined, pd = true) => {
  const elm = sel ? $(sel) : null;
  return e => {
    if (pd) {
      e.preventDefault();
    }
    const target = elm ? elm : $(e.target);
    target.toggleClass(cn, force);
  };
};

const handleRadioClick = e => {
  e.preventDefault();
  const elm = $(e.currentTarget);
  const name = elm.data('name');
  $(`button.radio[data-name="${name}"]`).removeClass('checked');
  elm.addClass('checked');
};

$(() => {
  $('header .toggle').on('click', cfToggleClass('body', 'menuopen', undefined, true));
  $('header.header input').on('focusin', cfToggleClass('body', 'searchfocused', true, false));
  $('header.header input').on('focusout', cfToggleClass('body', 'searchfocused', undefined, false));
  $('button.radio').on('click', handleRadioClick);
  $('header.header .menu .children button').each((i, e) => {
    const elm = $(e);
    const id = elm.data('id');
    const submenu = $(`header.header div[data-id="${id}"]`);
    elm.on('click', e => {
      $('header.header .menu .shown').removeClass('submenu');
      submenu.toggleClass('shown');
      elm.toggleClass('shown');
    });
  });
  $('header.header .menu a.current').each((i, e) => {
    const elm = $(e);
    elm.parents('.submenu2').addClass('shown');
    elm.parents('.submenu1').addClass('shown');
  });
  $('.newsletter .radio').on('click', e => {
    const fld = $(e.currentTarget);
    const frm = fld.parent('form');
    switch (fld.data('value')) {
      case 'ty':
        frm.attr('action', 'https://luetaanyhdessa.us16.list-manage.com/subscribe/post?u=8a27169d2b0cca90875b3d004&amp;id=511dd75852&amp;f_id=008f45e0f0');
        break;
      default:
        frm.attr('action', 'https://luetaanyhdessa.us16.list-manage.com/subscribe/post?u=8a27169d2b0cca90875b3d004&amp;id=1860b5f068&amp;f_id=008b45e0f0');
    }
  });

  $('a.share:not([href])').each((idx, e) => {
    const elm = $(e);
    const eUrl = encodeURIComponent(window.location.href)
    let href = '';
    if (elm.hasClass('li')) {
      href = `https://www.linkedin.com/shareArticle?mini=true&url=${eUrl}`;
    } else if (elm.hasClass('fb')) {
      href = `https://www.facebook.com/sharer/sharer.php?u=${eUrl}`;
    } else if (elm.hasClass('tw')) {
      href = `https://twitter.com/intent/tweet?text=${eUrl}`;
    } else if (elm.hasClass('mail')) {
      href = `mailto:?subject=Lukusuositus%3A%20${encodeURIComponent(document.title)}&body=${eUrl}`;
    }
    elm.attr('href', href);
  });
  $('section.teachinggrouplist').each((i, e) => {
    const elm = $(e);
    elm.find('input').on('keyup change', e2 => {
      const fv = $(e2.target).val().trim().toLocaleLowerCase();
      if (fv.length < 1) {
        elm.removeClass('filtered');
      } else {
        elm.addClass('filtered');
      }
      if (teachinggroups) {
        const selected = teachinggroups.map(i2 => {
          const inc = (
            i2.name.toLocaleLowerCase().includes(fv)
            ||
            i2.zone.toLocaleLowerCase().includes(fv)
            ||
            i2.address.toLocaleLowerCase().includes(fv)
            ||
            i2.info.toLocaleLowerCase().includes(fv)
          );
          return inc ? `#group${i2.id}` : null;
        }).filter(i => i);
        elm.find('.selected').removeClass('selected');
        $(selected.join(',')).addClass('selected')
          .parent('.city').addClass('selected')
          .parent('.county').addClass('selected');
      }
    });
  });
  var selectedBooks = {};
  $('section.contactform').each((i, e) => {
    const elm = $(e);
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      const tElm1 = elm.find(`[data-id="${hash}"]`);
      const tElm2 = elm.find(`form.${hash}`);
      const tElm = tElm1.length > 0 ? tElm1 : tElm2;
      if (tElm.length > 0) {
        const tElmClass = tElm[0].className;
        const tElmIdx = parseInt(tElmClass.replace(/[^\d]/g, ''), 10);
        elm.find('form.active').removeClass('active');
        tElm.addClass('active');
        elm.find('.formselect').val(`${tElmIdx}`);
      }
    }

    const rehashBookList = () => {
      console.log('rehashing book list');
      const strs = [];
      Object.entries(selectedBooks).sort((a, b) => {
        if (a[0] == b[0]) {
          return 0;
        }
        return a[0] < b[0] ? -1 : 1;
      }).map(i => {
        const link = $(`<div class="book">${i[1]} kpl ${i[0]}</div>`);
        link.on('click', e => {
          window.console.debug('removing book', i[0])
          delete selectedBooks[i[0]];
          rehashBookList();
        });  
        strs.push(link);
      });
      elm.find('.selectedbooks').empty();
      if (strs.length > 0) {
        elm.find('.selectedbooks').append(
          $('<h3>Tilauksen sisältö</h3>')
        ).append(
          strs
        );
      }
    }

    elm.find('.addbook input[type="button"]').on('click', e2 => {
      e2.preventDefault();
      const book = elm.find('.addbook select').val();
      const num = parseInt(elm.find('.addbook input[type="text"]').val(), 10);
      if (book.length > 0 && !isNaN(num) && num > 0) {
        selectedBooks[book] = num;
        if (selectedBooks[book] > 10) {
          selectedBooks[book] = 10;
        }
      }
      rehashBookList();
    });

    elm.find('.formselect').on('change', e2 => {
      const val = $(e2.target).val();
      elm.find('form.active').removeClass('active');
      elm.find(`form.form${val}`).addClass('active');
      window.location.hash = `form${val}`;
    });

    elm.find('form').on('submit', e2 => {
      e2.preventDefault();
      const tgt = $(e2.target);
      tgt.find('.invalid').removeClass('invalid');
      const data = new FormData();
      let invalid = false;
      data.append('action', 'contactform');
      tgt.find('input, textarea').each((i3, e3) => {
        const elm2 = $(e3);
        const key = elm2.attr('name');
        switch (elm2.attr('type')) {
          case 'file': {
            if (elm2[0].files[0]) {
              data.append(key, elm2[0].files[0]);
            }
            break;
          }
          case 'radio': {
            if (elm2.is(':checked')) {
              data.append(key, elm2.val());
            }
            break;
          }
          case 'checkbox': {
            if (elm2.is(':checked')) {
              data.append(key, elm2.val());
            }
            break;
          }
          case 'submit': {
            // nop
            break;
          }
          default:
            data.append(key, elm2.val().trim());
            const re = elm2.data('re') ? new RegExp(elm2.data('re')) : null;
            if (re && !re.exec(elm2.val().trim())) {
              elm2.parent().addClass('invalid');
              invalid = true;
            }
        }
      });
      const dataObj = Array.from(data).reduce((c, v) => {
        c[v[0]] = v[1];
        return c;
      }, {});
      if (tgt.hasClass('form1')) {
      } else if (tgt.hasClass('form2')) {
        if (selectedBooks.length < 1) {
          invalid = true;
        } else {
          data.append('books', JSON.stringify(selectedBooks));
        }
      } else if (tgt.hasClass('form3')) {

      } else {
        console.log('Unknown form');
        invalid = true;
      }
      if (invalid) {
        $('.invalid').trigger('focusin');
        return;
      }
      if (!invalid) {
        $.ajax({
          method: 'post',
          url: window.ajaxurl,
          data: data,
          processData: false,
          contentType: false,
          success: () => {
            tgt.find('input, textarea').val('');
            selectedBooks = {};
            rehashBookList();
            alert('Kiitos, lomake on lähetetty.');
          },
          error: () => {
            console.log('error');
          },
        });
      }
    });
  });
  $('section.toistogroup').each(function(i, e) {
    const elm = $(e);
    const btn = elm.find('button');
    const tag = elm.attr('id');
    btn.on('click', function(e) {
      e.preventDefault();
      if (!elm.hasClass('shown') && window.history && window.history.pushState) {
        window.history.pushState({}, null, '#' + tag);
      }
      elm.toggleClass('shown');
    });
  });
  $('a.vimeomodal').each(function (i, e) {
    const elm = $(e);
    const vid = elm.data('vimeo');
    if (vid) {
      elm.on('click', cfShowVimeoModal(vid));
    }
  });
  if (window.location.hash.length > 1) {
    const tag = window.location.hash.substring(1);
    const elm = $('#' + tag + ' button');
    if (elm.length > 0) {
      $(elm[0]).trigger('click');
    }
  }
});
